import traverse from "traverse";

import doesQueryUseFragment from "@utils/doesQueryUseFragment";

const TYPE_NAME = "WPGraphQL";
const FIELD_NAME = "wpgraphql";

export default query => {
  const updatedQuery = traverse(query).clone();

  const updatedRoot = updatedQuery.definitions[0].selectionSet.selections.find(
    selection =>
      selection.name &&
      selection.name.kind === "Name" &&
      selection.name.value === FIELD_NAME
  );

  if (updatedRoot) {
    updatedQuery.definitions[0].selectionSet.selections =
      updatedRoot.selectionSet.selections;
  }

  traverse(updatedQuery).forEach(function(currentValue) {
    if (this.isLeaf && this.parent && this.parent.key === "name") {
      if (this.parent.parent && this.parent.parent.node.kind === "NamedType") {
        if (
          typeof currentValue === "string" &&
          currentValue.indexOf(`${TYPE_NAME}_`) === 0
        ) {
          this.update(currentValue.substr(TYPE_NAME.length + 1));
        }
      }
    }
  });

  let index = 0;
  do {
    const definition = updatedQuery.definitions[index];

    if (definition.kind === "FragmentDefinition") {
      if (!doesQueryUseFragment(updatedQuery, definition.name.value)) {
        // delete fragment and start again, since other fragments possibly only
        // depended on the deleted one.
        updatedQuery.definitions.splice(index, 1);
        index = 0;
        continue;
      }
    }

    index += 1;
  } while (index < updatedQuery.definitions.length);

  return updatedQuery;
};
