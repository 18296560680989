import { graphql } from "gatsby";
import gql from "graphql-tag";
import React from "react";

import { previewFragments } from "@blocks/fragments";
import BlocksRenderer from "@components/BlocksRenderer";
import Footer from "@components/Footer";
import getPostData from "@utils/getPostData";
import useUIColorCheck from "@utils/useUIColorCheck";

const OpenPositionTemplate = props => {
  const { data, preview, transitionStatus } = props;
  useUIColorCheck([transitionStatus === "entered"]);
  const openPosition = getPostData("openPosition", { data, preview });

  return (
    <>
      <div className="open-position-page">
        <BlocksRenderer
          blocks={openPosition.blocks}
          entered={transitionStatus === "entered"}
        />
      </div>
      <Footer />
    </>
  );
};

export const query = graphql`
  query($wpId: Int!) {
    wpgraphql {
      openPosition: openPositionBy(openPositionId: $wpId) {
        uri
        title
        status
        slug
        content

        acf {
          salary
        }

        blocks {
          ...Roll_Block
        }
      }
    }
  }
`;

export const previewQuery = gql`
  query($wpId: Int!) {
    wpgraphql {
      openPosition: openPositionBy(openPositionId: $wpId) {
        blocks {
          ...Roll_Block
        }
        blocksPreview {
          ...Roll_Block
        }

        preview: revisions(first: 1, before: null) {
          nodes {
            uri
            title
            status
            slug
            content

            acf {
              salary
            }
          }
        }
      }
    }
  }

  ${previewFragments}
`;

export default OpenPositionTemplate;
