import React, { useMemo } from "react";
import { useQuery } from "react-apollo-hooks";

import getIsolatedQuery from "@utils/getIsolatedQuery";

const PreviewFetcher = ({ query, id, nextId, wpnonce, children }) => {
  const context = {
    headers: {
      "X-WP-Nonce": wpnonce,
    },
    credentials: "include",
  };

  const isolatedQuery = useMemo(() => getIsolatedQuery(query), [query]);

  const { data, error, loading: isLoading } = useQuery(isolatedQuery, {
    query: isolatedQuery,
    context,
    variables: { wpId: id, nextId: nextId },
  });

  if (error) {
    return <div>{error}</div>;
  }

  if (isLoading) {
    return <div>Loading preview...</div>;
  }

  return <>{children(data)}</>;
};

export default PreviewFetcher;
