import traverse from "traverse";

export default (query, fragment) => {
  let queryUsesFragment = false;

  traverse(query).forEach(function(currentValue) {
    // We're looking for this kind of construct
    // {
    //   "kind": "FragmentSpread", // 1
    //   "name": {                 // 2
    //     "kind": "Name",
    //     "value": "<fragment>"   // 3, currentValue
    //   }
    // }

    if (
      this.isLeaf &&
      this.key === "value" && // 3
      this.parent &&
      this.parent.key === "name" && // 2
      this.parent.parent &&
      this.parent.parent.node.kind === "FragmentSpread" // 1
    ) {
      if (currentValue === fragment) {
        queryUsesFragment = true;
      }
    }
  });

  return queryUsesFragment;
};
