import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import fetch from "isomorphic-fetch";
import qs from "query-string";
import React, { useState, useEffect } from "react";
import { ApolloProvider } from "react-apollo-hooks";

import PreviewFetcher from "@components/PreviewFetcher";
import introspectionQueryResultData from "@data/schema.json";
import OpenPositionTemplate, {
  previewQuery as openPositionTemplateQuery,
} from "@templates/OpenPositionTemplate";
import PageTemplate, {
  previewQuery as pageTemplateQuery,
} from "@templates/PageTemplate";
import ProjectTemplate, {
  previewQuery as projectTemplateQuery,
} from "@templates/ProjectTemplate";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const Preview = props => {
  const [previewParams, setPreviewParams] = useState(null);

  useEffect(() => {
    const { type, id, nextId = "", wpnonce } = qs.parse(window.location.search);

    let Template = PageTemplate;
    let query = pageTemplateQuery;

    switch (type) {
      case "open-position":
        Template = OpenPositionTemplate;
        query = openPositionTemplateQuery;
        break;

      case "project":
        Template = ProjectTemplate;
        query = projectTemplateQuery;
        break;

      default:
        Template = PageTemplate;
        query = pageTemplateQuery;
    }

    const apolloClient = new ApolloClient({
      cache: new InMemoryCache({ fragmentMatcher }),
      link: new HttpLink({
        uri: `${process.env.API_BASE_URL}/graphql`,
        headers: {
          Authorization: `Basic ${btoa(
            process.env.HTTP_AUTH_USERNAME +
              ":" +
              process.env.HTTP_AUTH_PASSWORD
          )}`,
        },
        fetch,
      }),
    });

    setPreviewParams({
      apolloClient,
      type,
      Template,
      query,
      id,
      nextId,
      wpnonce,
    });
  }, []);

  if (!previewParams) {
    return null;
  }

  const { apolloClient, Template, query, id, nextId, wpnonce } = previewParams;

  return (
    <ApolloProvider client={apolloClient}>
      <PreviewFetcher query={query} id={id} nextId={nextId} wpnonce={wpnonce}>
        {data => (
          <Template
            preview={data}
            location={props.location}
            transitionStatus={props.transitionStatus}
          />
        )}
      </PreviewFetcher>
    </ApolloProvider>
  );
};

export default Preview;
